import React, { Suspense, useContext, useEffect, useState } from 'react';

import Burden from './components/storyblok/Burden';
import { Video } from './components/storyblok/Video';

import { ComponentLookupContext, StoryRouter, DynamicComponent, MobileLayout, NavigationItem, SidebarContext, useResponsiveValue, useStoryblokNavigation, useStoryblokPaths, ParagraphBlok, useStoryblokNavigationTree } from '@tjclifton/storyblok-react-utils';
import { Button, Container, Grid, GridColumn, SemanticVERTICALALIGNMENTS } from 'semantic-ui-react';
import { StoryData } from 'storyblok-js-client';

import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import classNames from 'classnames';
import { Page } from './components/storyblok/Page';
import Reveal from 'react-reveal/Reveal';

import 'animate.css';

import styles from '@styles/App.module.sass';

import logoUrl from '@images/passion-logo.png';

/**
 *
 */
declare const window: {
  FB?: {
    CustomerChat?: {
      hide(): void;
    }
  }
};

/**
 *
 */
interface ContentProps {
  readonly navItems: ReadonlyArray<NavigationItem>;
}

/**
 *
 * @param props
 */
const Content: React.FC<ContentProps> = props => {
  const [ scrolled, setScrolled ] = useState(false);
  const { setVisible: setSidebarVisible } = useContext(SidebarContext);
  const { pathFor, nameFor } = useStoryblokPaths();

  const lessPadded = useResponsiveValue({max: 'md'}, true, false);

  const onContentChange = useCallback((content?: StoryData<unknown>) => {
    if (content?.full_slug !== 'contact') window.FB?.CustomerChat?.hide();
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', () => setScrolled(true));
  }, []);

  return <StoryRouter
    storyType="page"
    contentFields={{
      content: {
        headerImage: {
          filename: 'include'
        },
        headerText: [{}] as ParagraphBlok[] | null,
        headerTextReveal: 'include' as string | null,
        headerTextVerticalAlignment: 'include' as SemanticVERTICALALIGNMENTS | null
      }
    }}
    wrapperClasses={[styles.wrapper]}
    notFoundSlug="not-found"
    onStoryChange={onContentChange}
    loaderColor={'red'}
  >
    {data =>
      <div
        className={classNames(
          styles.container,
          {[styles.lessPaddedContainer]: lessPadded},
        )}
      >
        <header
          className={styles.header}
          style={{
            backgroundImage: `url(${data.content.headerImage?.filename})`
          }}
        >
          <Reveal effect="animate__fadeInUp" appear when={true}>
            <div
              className={styles.bannerTextWrapper}
              style={{
                alignItems: {
                  top: 'flex-start',
                  middle: 'center',
                  bottom: 'flex-end',
                }[data.content.headerTextVerticalAlignment || 'middle']
              }}
            >
              <div>
                <Suspense fallback>
                  {data.content.headerText?.map(item =>
                    <DynamicComponent key={item._uid} blok={item} />
                  )}
                </Suspense>
              </div>
            </div>
          </Reveal>

          <Grid columns={3} verticalAlign="top">
            <Grid.Column mobile={8} tablet={4} computer={3}>
              <Link className={styles.logo} to="/">
                <img src={logoUrl} />
              </Link>
            </Grid.Column>
            <Grid.Column only="tablet" tablet={8} computer={12}></Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={1} textAlign="right">
              <Button size="large" basic className={styles.menuButton} inverted icon="bars" onClick={() => setSidebarVisible(true)} />
            </Grid.Column>
          </Grid>
        </header>

        <Container text>

          {/* Main content */}
          <section>
            <Suspense fallback={null}>
              <DynamicComponent blok={data.content} />
            </Suspense>
          </section>
        </Container>

        {/* Footer */}
        <footer className={styles.footer}>
          <Grid stackable columns={3}>
            <GridColumn></GridColumn>
            <GridColumn textAlign="center">
              Copyright &copy; 2021.
            </GridColumn>
            <GridColumn></GridColumn>
          </Grid>
        </footer>
      </div>
    }
  </StoryRouter>;
};

/**
 *
 * @param props
 * @returns
 */
export const App: React.FC = props => {
  const { links } = useStoryblokNavigationTree();

  return <MobileLayout navItems={links || []} direction="right">
    <ComponentLookupContext.Provider value={{
      Burden,
      Video,
      Page
    }}>
      <Content navItems={links || []} />
    </ComponentLookupContext.Provider>
  </MobileLayout>;
};
